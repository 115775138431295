import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Button, Checkbox, Icon, Input, Modal, Select, Table } from "semantic-ui-react"
import moment from 'moment';
import { updateCurrentClientProp } from "../../actions/client.actions";
import { sysAPI } from "../../apis/SysAPI";

export const FamilyMembersModal = ({ open, closeBtn_Click, type, editingEnabled }) => {
    const dispatch = useDispatch();

    const client = useSelector(state => state.currentClient);

    const options = type === 'trusts'
        ? ['Trust', 'Corp']
        : ['Spouse', 'Child', 'Grandchild', 'Sibling of the Client', 'Mother of the Client', 'Father of the Client', 'Mother of the Co-Client', 'Father of the Co-Client', 'Brother of the Co-Client', 'Sister of the Co-Client', 'Family Member', 'Other'];
    const _options = options.map(o => ({ key: o, value: o, text: o }));

    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [relation, setRelation] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [relatedTo, setRelatedTo] = useState('');
    const [isDeceased, setIsDeceased] = useState(false);
    const [dontDisplay, setDontDisplay] = useState(false);
    const [familyMemberAdditionalOpts, setFamilyMemberAdditionalOpts] = useState([]);
    const [parentOne, setParentOne] = useState('');
    const [parentTwo, setParentTwo] = useState('');
    const [parentsOptions, setParentsOptions] = useState([]);
    const [spouseOptions, setSpouseOptions] = useState([]);
    const [currentContact, setCurrentContact] = useState(undefined);

    const addBtn_Click = async () => {
        const toAdd = {
            firstName,
            lastName,
            middleName,
            relation: relation,
            type: relation === 'Trust' ? 'trust' : relation === 'Corp' ? 'corp' : 'family',
            relatedTo,
            birthdate,
            addons: {
                source: 'manual_input',
                isDeceased,
                dontDisplay
            },
        };

        if (relation === 'Child') {
            toAdd.addons.parent_1 = parentOne;
            toAdd.addons.parent_2 = parentTwo;
        }

        try {
            const {data} = await sysAPI.patch(`/clients/${client._id}/contacts`, { contacts: [toAdd] })
            dispatch(updateCurrentClientProp('estateContactsList', data.contacts));
            setFirstName('');
            setMiddleName('');
            setLastName('');
            setRelation('');
            setBirthdate('');
            setRelatedTo('');
            setIsDeceased(false);
            setDontDisplay(false);
            //dispatch(updateCurrentClientProp('estateContactsList', [ ...client.estateContactsList, toAdd ]));
        } catch (error) {
            console.log({error})
            const message = error?.response?.data?.message || error?.message || "There was an error saving the changes. Please try again later."
            alert(message)
        }
    };

    const removeBtn_Click = (contact) => {
        return () => {
            const confirm = window.confirm(`Are you sure you want to delete ${contact.firstName} ${contact.lastName}?`);
            if (confirm) {
                sysAPI.delete(`/clients/${client._id}/contacts`, { data: { contactsIds: [contact._id] } })
                .then(response => {
                    dispatch(updateCurrentClientProp('estateContactsList', response.data.contacts));
                }).catch(err => {
                    window.alert('There was an error saving the changes. Please try again later.');
                });
            }
        }
    };

    const editBtn_Click = (contactId, activeEdit = true) => {
            const auxContacts = [...client.estateContactsList];
            const auxContact = auxContacts.find(c => c._id === contactId);
            auxContact.isEdit = activeEdit;
            setCurrentContact(auxContact._id);

            auxContact.editState = {
                firstName: auxContact.firstName,
                middleName: auxContact.middleName,
                lastName: auxContact.lastName,
                relation: auxContact.relation,
                birthdate:  auxContact.birthdate && moment(auxContact.birthdate).isValid()
                    ? moment(auxContact.birthdate).format('YYYY-MM-DD')
                    : '-'
            };
            dispatch(updateCurrentClientProp('estateContactsList', auxContacts));
    };

    const cancelEditBtn_Click = (contactId) => {
        return () => {
            const auxContacts = [...client.estateContactsList];
            const auxContact = auxContacts.find(c => c._id === contactId);
            auxContact.isEdit = undefined;
            auxContact.editState = undefined;
            setCurrentContact(undefined);
            dispatch(updateCurrentClientProp('estateContactsList', auxContacts));
        }
    };

    const editInput_Change = (contactId, prop, value) => {
        const auxContacts = [...client.estateContactsList];
        const auxContact = auxContacts.find(c => c._id === contactId);
        auxContact.editState[prop] = value;
        console.log({value, contactId, prop, auxContact})
        dispatch(updateCurrentClientProp('estateContactsList', auxContacts));
    };

    const saveEditBtn_Click = (contactId) => {
        const auxContacts = [...client.estateContactsList];
        const auxContact = auxContacts.find(c => c._id === contactId);
        auxContact.isEdit = undefined;

        auxContact.firstName = auxContact.editState.firstName;
        auxContact.lastName = auxContact.editState.lastName;
        auxContact.middleName = auxContact.editState.middleName;
        auxContact.relation = auxContact.editState.relation;
        auxContact.birthdate = auxContact.editState.birthdate;

        if(!auxContact.addons){
            auxContact.addons = {}
        }

        if(auxContact.editState.relation === 'Child') {
            auxContact.addons.parent_1 = auxContact.editState['addons.parent_1'];
            auxContact.addons.parent_2 = auxContact.editState['addons.parent_2'];
        }

        auxContact.addons.isDeceased = !!auxContact.editState["addons.isDeceased"]
        auxContact.addons.dontDisplay = !!auxContact.editState["addons.dontDisplay"]
        if(["Self", "Spouse"].includes(auxContact.relation)){
            auxContact.addons.dontDisplay = false;
        }
        auxContact.editState = undefined;

        sysAPI.patch(`/clients/${client._id}/contacts`, { contacts: [auxContact] })
        .then(response => {
            dispatch(updateCurrentClientProp('estateContactsList', response.data.contacts));
            setCurrentContact(undefined);
        }).catch(err => {
            window.alert('There was an error saving the changes. Please try again later.');
        });
    }

    const listedContacts = type === 'trusts'
        ? client?.estateContactsList?.filter(c => c.type === 'trust' || c.type === 'corp')
        : client?.estateContactsList?.filter(c => c.type === 'family');

    /* const dateFormatting = (date) => {
            return date && moment(date).isValid()
                ? moment(date).format('MM/DD/YYYY')
                : '-';
        } */

    const buildParentName = (parentId) => {
        if (!parentId) {
            return '';
        }

        const person = client.estateContactsList.filter(item => item._id === parentId);
        return `${person[0]?.firstName} ${person[0]?.lastName}` || 'N/A';
    }

    useEffect(() => {
        if (client) {
            const self = client.estateContactsList.filter(item => item.relation === 'Self');
            const spouse = client.estateContactsList.filter(item => item.relation === 'Spouse') || [];

            const parentOneOptions = [
                {
                    key: self[0]._id,
                    value: self[0]._id,
                    text: `${self[0].firstName} ${self[0].lastName}`,
                },
            ];

            const parentTwoOptions = [
                {
                    key: '',
                    value: '',
                    text: '',
                },
            ];

            if (spouse.length >= 1) {
                const spouseOptionData = {
                    key: spouse[0]._id,
                    value: spouse[0]._id,
                    text: `${spouse[0].firstName} ${spouse[0].lastName}`,
                };

                parentOneOptions.push(spouseOptionData);
                parentTwoOptions.push(spouseOptionData);
            }

            setParentsOptions(parentOneOptions);
            setSpouseOptions(parentTwoOptions);
        }

        setParentOne(parentsOptions[0]?.value);
        // eslint-disable-next-line
    }, [client]);

    useEffect(() => {
        if (currentContact) {
            const auxContacts = [...client.estateContactsList];
            const auxContact = auxContacts.find(c => c._id === currentContact);
            if (auxContact.relation === 'Child') {
                if (auxContact?.addons?.parent_1) auxContact.editState['addons.parent_1'] = auxContact?.addons?.parent_1;
                if (auxContact?.addons?.parent_2) auxContact.editState['addons.parent_2'] = auxContact?.addons?.parent_2;
                dispatch(updateCurrentClientProp('estateContactsList', auxContacts));
            }
        }
        
        // eslint-disable-next-line
    }, [currentContact]);


    return (
        <Modal open={open} size='large' style={{width: "1200px"}}>
            <Modal.Header>{type === 'trusts' ? `Entities` : `Family Members & Others`}</Modal.Header>
            <Modal.Content>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            {type !== 'trusts' && <>
                                <Table.HeaderCell>Don't Display</Table.HeaderCell>
                                <Table.HeaderCell>First Name</Table.HeaderCell>
                                <Table.HeaderCell>Middle Name</Table.HeaderCell>
                                <Table.HeaderCell>Last Name</Table.HeaderCell>
                                <Table.HeaderCell>Relation</Table.HeaderCell>
                                {/* <Table.HeaderCell>Birthdate</Table.HeaderCell> */}
                                <Table.HeaderCell>Parents</Table.HeaderCell>
                                <Table.HeaderCell>Is Deceased</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </>}
                            {type === 'trusts' && <>
                                <Table.HeaderCell>Full Name / Description</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(listedContacts || []).map(contact => {
                            return (<Table.Row>
                                {type === 'trusts' && !contact.isEdit && <>
                                    <Table.Cell>{contact.firstName}</Table.Cell>
                                    <Table.Cell>{contact.relation}</Table.Cell>
                                </>}
                                {type === 'trusts' && contact.isEdit && <>
                                    <Table.Cell><Input value={contact.editState.firstName} onChange={(e, {value}) => editInput_Change(contact._id, 'firstName', value)} /></Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            fluid
                                            selection
                                            search
                                            options={[{ value: 'Trust', text: 'Trust' }, { value: 'Corp', text: 'Organization' }]}
                                            value={contact.editState.relation}
                                            onChange={(e, {value}) => editInput_Change(contact._id, 'relation', value)}
                                        />
                                    </Table.Cell>
                                </>}
                                {type !== 'trusts' && !contact.isEdit && (<>
                                    <Table.Cell>{contact?.addons?.dontDisplay ? 'Yes' : 'No'}</Table.Cell>
                                    <Table.Cell>{contact.firstName}</Table.Cell>
                                    <Table.Cell>{contact.middleName || '-'}</Table.Cell>
                                    <Table.Cell>{contact.lastName || '-'}</Table.Cell>
                                    <Table.Cell>{contact.relation}</Table.Cell>
                                    {/* <Table.Cell>{dateFormatting(contact.birthdate)}</Table.Cell> */}
                                    <Table.Cell>
                                        {contact.relation === 'Child' &&
                                            `${buildParentName(contact.addons.parent_1)}
                                            ${contact.addons.parent_2 ? ` & ${buildParentName(contact.addons.parent_2)}` : ''}`}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {contact?.addons?.isDeceased ? 'Yes' : 'No'}
                                    </Table.Cell>
                                </>)}
                                {type !== 'trusts' && contact.isEdit && (<>
                                    <Table.Cell>
                                        {!["Self", "Spouse"].includes(contact.relation) && 
                                            <Checkbox label={""} defaultChecked={contact?.addons?.dontDisplay} onChange={(_, {checked}) => editInput_Change(contact._id, 'addons.dontDisplay', checked)} />
                                        }
                                    </Table.Cell>
                                    <Table.Cell><Input value={contact.editState.firstName} onChange={(e, {value}) => editInput_Change(contact._id, 'firstName', value)} /></Table.Cell>
                                    <Table.Cell><Input value={contact.editState.middleName} onChange={(e, {value}) => editInput_Change(contact._id, 'middleName', value)} /></Table.Cell>
                                    <Table.Cell><Input value={contact.editState.lastName} onChange={(e, {value}) => editInput_Change(contact._id, 'lastName', value)} /></Table.Cell>
                                    <Table.Cell>
                                        {/* {contact.relation === 'Self' && contact.relation} */}
                                        {
                                            <Select
                                                fluid
                                                selection
                                                search
                                                options={[..._options, ...familyMemberAdditionalOpts]}
                                                value={contact.editState.relation}
                                                onChange={(e, {value}) => editInput_Change(contact._id, 'relation', value)}
                                            />
                                        }
                                    </Table.Cell>
                                    {/* <Table.Cell><Input type='date' value={contact.editState.birthdate} onChange={(e, {value}) => editInput_Change(contact._id, 'birthdate', value)} /></Table.Cell> */}
                                    <Table.Cell>
                                        {contact.editState.relation === 'Child' &&
                                            <>
                                                <Select
                                                    fluid
                                                    options={parentsOptions}
                                                    value={contact.editState['addons.parent_1'] || ''}
                                                    onChange={(e, {value}) => editInput_Change(contact._id, 'addons.parent_1', value)}
                                                    placeholder="Parent 1"
                                                />
                                                <Select
                                                    fluid
                                                    options={spouseOptions}
                                                    value={contact.editState['addons.parent_2'] || ''}
                                                    onChange={(e, {value}) => editInput_Change(contact._id, 'addons.parent_2', value)}
                                                    placeholder="Parent 2"
                                                />
                                            </>}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox label={""} defaultChecked={contact?.addons?.isDeceased} onChange={(_, {checked}) => editInput_Change(contact._id, 'addons.isDeceased', checked)} />
                                    </Table.Cell>
                                </>)}
                                <Table.Cell>
                                    {editingEnabled && !contact.isEdit &&
                                        <div style={{ display: 'flex' }}>
                                            <Icon size="large" name="edit outline" color="grey" onClick={() => editBtn_Click(contact._id)} style={{cursor:"pointer"}} />
                                            {contact.relation !== 'Self' &&
                                                <Icon size='large' name='remove' color='red' onClick={removeBtn_Click(contact)} style={{cursor:"pointer"}} />
                                            }
                                        </div>
                                    }
                                    {contact.isEdit &&
                                        <div style={{ display: 'flex' }}>
                                            <Icon size="large" name="cancel" color="grey" onClick={cancelEditBtn_Click(contact._id)} style={{cursor:"pointer"}} />
                                            <Icon size="large" name="save" color="green" onClick={() => saveEditBtn_Click(contact._id)} style={{cursor:"pointer"}} />
                                        </div>
                                    }
                                </Table.Cell>
                            </Table.Row>)
                        })}
                        {editingEnabled &&
                            <Table.Row>
                                {type === 'trusts' && <>
                                    <Table.Cell><Input fluid value={firstName} onChange={e => setFirstName(e.currentTarget.value)} /></Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            fluid
                                            options={[{ value: 'Trust', text: 'Trust' }, { value: 'Corp', text: 'Organization' }]}
                                            value={relation}
                                            onChange={(e, elem) => setRelation(elem.value)}
                                        />
                                    </Table.Cell>
                                </>}
                                {type !== 'trusts' && <>
                                    <Table.Cell>
                                        <Checkbox label={""} checked={dontDisplay} onChange={(_, {checked}) => setDontDisplay(checked)} />
                                    </Table.Cell>
                                    <Table.Cell><Input fluid value={firstName} onChange={e => setFirstName(e.currentTarget.value)} /></Table.Cell>
                                    <Table.Cell><Input fluid placeholder='[Can be empty]' value={middleName} onChange={e => setMiddleName(e.currentTarget.value)} /></Table.Cell>
                                    <Table.Cell><Input fluid placeholder='[Can be empty]' value={lastName} onChange={e => setLastName(e.currentTarget.value)} /></Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            fluid
                                            allowAdditions
                                            selection
                                            search
                                            options={[..._options, ...familyMemberAdditionalOpts]}
                                            value={relation}
                                            onChange={(e, elem) => setRelation(elem.value)}
                                            onAddItem={(e, { value }) => setFamilyMemberAdditionalOpts([...familyMemberAdditionalOpts, { value, text: value }])}
                                        />
                                    </Table.Cell>
                                    {/* <Table.Cell><Input type='date' value={birthdate} onChange={e => setBirthdate(e.currentTarget.value)} /></Table.Cell> */}
                                    <Table.Cell>
                                        {relation === 'Child' &&
                                            <>
                                                <Select
                                                    fluid
                                                    allowAdditions
                                                    selection
                                                    options={parentsOptions}
                                                    value={parentOne}
                                                    onChange={(e, elem) => setParentOne(elem.value)}
                                                    placeholder="Parent 1"
                                                    // defaultValue={parentsOptions[0].value}
                                                />
                                                <Select
                                                    fluid
                                                    allowAdditions
                                                    selection
                                                    options={spouseOptions}
                                                    value={parentTwo}
                                                    onChange={(e, elem) => setParentTwo(elem.value)}
                                                    placeholder="Parent 2"
                                                />
                                            </>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox label={""} checked={isDeceased} onChange={(_, {checked}) => setIsDeceased(checked)} />
                                    </Table.Cell>
                                </>}
                                <Table.Cell style={{ width: '50px' }}>
                                    <Icon size='large' name='add circle' color='green' onClick={() => addBtn_Click()} style={{cursor:"pointer"}} />
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={closeBtn_Click}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}